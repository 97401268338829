<template>
    <div class="base-hashtags">
        <div class="base-hashtags__field">
            <v-chip
                v-for="chip in value"
                :key="chip"
                class="mr-2 my-1"
                close
                @click:close="removeChip(chip)">
                #{{ chip }}
            </v-chip>

            <input
                type="text"
                class="base-hashtags__input"
                :placeholder="placeholder"
                :label="label"
                @keyup.enter="addChip"
                @keyup.space="addChip"
                @blur="addChip" />
        </div>

        <div v-if="items.length" class="base-hashtags__examples">
            <v-chip
                v-for="chip in items"
                :key="chip"
                outlined
                class="mr-2 my-1"
                @click="addExampleChip(chip)">
                #{{ chip }}
            </v-chip>
            <span
                v-if="others"
                class="base-hashtags__others"
                @click="$emit('click:others')">
                + Еще
            </span>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'BaseHashtags',
        model: {
            prop: 'value',
            event: 'change',
        },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            items: {
                type: Array,
                default: () => [],
            },
            others: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: ''
            },
        },
        methods: {
            addChip(e) {
                let value = _.uniq(e.target.value.split(','));
                value = value.map(chip => {
                    chip.trim();
                    chip = chip.replace(/^(#)*/, '');
                    return chip;
                }); // trim
                value = value.filter(chip => !!chip); // remove empty items
                value = value.filter(chip => this.value.indexOf(chip) === -1);

                this.$emit('change', [...this.value, ...value]);
                e.target.value = '';
            },
            addExampleChip(chipToAdd) {
                if (this.value.indexOf(chipToAdd) === -1) {
                    this.$emit('change', [...this.value, chipToAdd]);
                }
            },
            removeChip(chipToRemove) {
                const chips = this.value.filter(chip => chip !== chipToRemove);
                this.$emit('change', chips);
            },
        },
    };
</script>

<style lang="scss">
.base-hashtags {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;

    .theme--light.v-chip:not(.v-chip--active) {
        background: #DA0A63;
        border-radius: 30px !important;
    }
}

.base-hashtags__field {
    min-height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #EBEBEB;
    border-radius: 10px;
    padding: 8px 12px;
    box-shadow: rgba(30, 28, 46, 0.03);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
}

.base-hashtags__examples {
    min-height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0;
    font-family: IBM Plex Sans, Arial, sans-serif;
    font-size: 13px;

    > span {
        color: #4a4b50 !important;
    }

    > .base-hashtags__others {
        font-family: IBM Plex Sans, Aral, sans-serif;
        color: red !important;
        cursor: pointer;
        font-size: 14px;
    }
}

.base-hashtags__input {
    border: none;
    outline: none;
    padding: 0 5px;
    flex: 1;
    width: 20px;
    display: block;
    font-family: IBM Plex Sans, Arial, sans-serif;
    font-size: 14px;
}
</style>
