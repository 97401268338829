<template>
    <div class="base-uploader">
        <div class="base-uploader__field" @click="getFile">
            {{ text }}
            <v-icon class="mr-1" color="#FF385C">
                mdi-upload
            </v-icon>
        </div>
        <!-- <v-input
            class="base-uploader__field"
            label="Презентация в PDF"
            :value="value"
        /> -->
        <input
            ref="file"
            type="file"
            accept=".pdf"
            class="display-none"
            @change="upload"
        />
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'BaseUploader',
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            text: {
                type: String,
                default: ''
            }
        },
        methods: {
            getFile() {
                this.$refs.file.click();
            },
            async upload(e) {
                const filePath = await store.dispatch('files/upload', { file: e.target.files[0] });
                this.$emit('change', filePath)
            },
        }
    }
</script>

<style lang='scss'>
.base-uploader {
    display: flex;
    flex-direction: row;
    width: 100%;
    // &__field {
    //     height: 56px;
    //     // width: 100%;
    //     border: 1px solid #EBEBEB;
    //     box-sizing: border-box;
    //     border-radius: 10px;
    // }
    &__field {
        width: 175px;
        height: 166px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px dashed #dbdae0;
        border-radius: 12px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        margin: 10px 0;
    }
    .display-none {
        display: none;
    }
    .v-input .v-label {
        top: 18px;
        left: 18px !important;
        @media all and(min-width: 768px) {
            display: none;
        }
    }
}
</style>
