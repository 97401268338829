<template>
    <v-radio
        class="base-radio"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
    export default {
        name: 'BaseRadio',
        inheritAttrs: false
    }
</script>

<style lang="scss">
    $red: #E2231A;

    .base-radio {
        @media all and (min-width: 768px) {
                margin-right: 20px;
                display: flex;
            }
        .v-input--selection-controls__input {
            background: #fff;
            display: flex;
            justify-content: center;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            width: 18px;
            height: 18px;
            @media all and (min-width: 768px) {
                margin-right: 10px;
            }
        }
        .v-input--selection-controls__ripple {
            display: none;
        }
        // .mdi-radiobox-blank::before {
        //     content: none;
        // }
        // .mdi-radiobox-marked::before {
        //     content: \2713;
        //     background: $red;
        // }
        .v-item--active .v-icon.v-icon {
            width: 16px;
            height: 16px;
            background: $red;
            border-radius: 8px;
            top: 3px;
            left: 3px;
            position: absolute;
            color: $red;
            caret-color: $red;
        }
        .v-label {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: #0D0D12;
        }
    }
</style>
